import React, { useCallback, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { isMobile } from "react-device-detect";
// import MobileSuggestion from "./MobileSuggestion";
import { useRouter } from "next/router";
import { useGlobalState } from "utils/container";

const USE_WEB_COOKIE = "USE_WEB";

const MobileSuggestionContainer: React.FC = () => {
  const stateCtx: any = useGlobalState();
  const router = useRouter();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(isMobile && Cookies.get(USE_WEB_COOKIE) !== "true");
  }, []);

  const handleGoToAppStore = useCallback(() => {
    router.push("https://komiapp.page.link/komiapp");
    // window.location.href = "https://komiapp.page.link/komiapp";
  }, []);

  const handleContinueWeb = useCallback(() => {
    Cookies.set(USE_WEB_COOKIE, "true");
    setVisible(false);
  }, []);

  if (!visible || !stateCtx?.visibleMobileSuggestion) return null;

  return null;
};

export default MobileSuggestionContainer;
